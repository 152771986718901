import React, { Fragment, useMemo } from "react";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchBidListingThunkAction } from "redux/biding/actions";
import { selectBidList } from "redux/biding/selectors";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Loader/Loader";
import Paginate from "components/Pagination/Pagination";
import BidListingCard from "components/Cards/BidListingCard";
import { GenerateNAICScode } from "services/firmBidOpportunityService";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
const initialState = {
  startDate: "",
  endDate: "",
  range: "",
  agencyType: [],
  category: [],
  location: [],
  jobType: [],
  sortBy: "",
  columnName: "",
  limit: 10,
  bidType: null,
  search: ""
};

function JobMatchmaking() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const firmName = searchParams.get("FirmName");
  const firmDescription = searchParams.get("FirmDescription")
    ? decodeURIComponent(searchParams.get("FirmDescription"))
    : "";
  const NAICScode = searchParams.get("generateNAICScode");
  const MatchingJob = searchParams.get("viewMatchingJob");
  const pageParams = searchParams.get("page") || 0;

  const [filterData, setFilterData] = React.useState({ ...initialState });
  const [error, setError] = React.useState(null);
  const [viewMatchingJob, setViewMatchingJob] = React.useState(MatchingJob || false);
  const [generateNAICScode, setGenerateNAICScode] = React.useState(NAICScode || "");
  const [generateCodeLoading, setGenerateCodeLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({ FirmName: "", FirmDescription: "" });
  const dispatch = useDispatch();
  const { isLoading, bidList, totalPage, naics_code } = useSelector(selectBidList);

  const [currentPage, setCurrentPage] = React.useState(pageParams);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      FirmName: firmName || "",
      FirmDescription: firmDescription || ""
    },
    validationSchema: Yup.object().shape({
      FirmName: Yup.string().required("Firm name is required"),
      FirmDescription: Yup.string().required("Firm description is required")
      // .min(6)
    }),
    onSubmit: async (values) => {
      try {
        setFormData({
          FirmName: values.FirmName,
          FirmDescription: values.FirmDescription
        });
        setGenerateCodeLoading(true);
        setViewMatchingJob(false);
        const { data } = await GenerateNAICScode({
          firmName: values.FirmName,
          description: values.FirmDescription
        });
        if (data.statusCode !== 200) {
          throw new Error(data.message);
        }
        setGenerateNAICScode(data.data.generated_naics_codes);
        setGenerateCodeLoading(false);
        setViewMatchingJob(true);
        toast.success("Job Matches found. Explore now.");
      } catch (error) {
        console.log(error);
        toast.error(error.message);
        setGenerateCodeLoading(false);
        setViewMatchingJob(false);
      }
    }
  });

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePreviousPage = () => {
    if (currentPage === 0) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage === totalPage - 1) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  React.useEffect(() => {
    if (viewMatchingJob) {
      dispatch(fetchBidListingThunkAction(filterData, currentPage, onSuccess, onError));
    }
  }, [filterData, currentPage, viewMatchingJob, window.location.search]);

  const appendQueryParams = (generateNAICScode, formData, viewMatchingJob) => {
    console.log(generateNAICScode, formData, viewMatchingJob);
    let query = "";
    if (generateNAICScode) {
      query += `&generateNAICScode=${generateNAICScode}`;
    }
    if (formData.FirmName) {
      query += `&FirmName=${formData.FirmName}`;
    }
    if (formData.FirmDescription) {
      query += `&FirmDescription=${encodeURIComponent(formData.FirmDescription)}`;
    }
    if (viewMatchingJob) {
      query += `&viewMatchingJob=${viewMatchingJob}`;
    }
    return query;
  };

  useMemo(() => {
    navigate(
      `?page=${currentPage}${appendQueryParams(generateNAICScode, formData, viewMatchingJob)}`
    );
  }, [viewMatchingJob, generateNAICScode, currentPage, formData]);

  return (
    <div className="px-3">
      <div className="my-3">
        <h4>Quick Job Matchmaking</h4>
      </div>
      <Row>
        <Col lg="12">
          <Card
            className="card-stats mb-4 mb-xl-0"
            style={{
              backgroundColor: "#E7F1FD",
              borderRadius: "10px"
            }}
          >
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">Enter Firm name*</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Firm name"
                        {...formik.getFieldProps("FirmName")}
                      />
                      {formik.touched.FirmName && formik.errors.FirmName ? (
                        <div className="text-danger mt-2">{formik.errors.FirmName}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg="8">
                    <FormGroup>
                      <label>Enter Firm description*</label>
                      <textarea
                        rows="1"
                        className="form-control"
                        placeholder="Enter Firm description"
                        {...formik.getFieldProps("FirmDescription")}
                      />
                      {formik.touched.FirmDescription && formik.errors.FirmDescription ? (
                        <div className="text-danger mt-2">{formik.errors.FirmDescription}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="justify-content-end d-flex my-2">
                    <button
                      type="submit"
                      disabled={generateCodeLoading}
                      className="btn btn-primary mr-3"
                    >
                      {generateCodeLoading ? "View Matching Jobs ..." : "View Matching Jobs"}
                    </button>
                    <button
                      onClick={() => {
                        setGenerateNAICScode("");
                        formik.resetForm();
                        setViewMatchingJob(false);
                        setFormData({ FirmName: "", FirmDescription: "" });
                      }}
                      className="btn btn-secondary"
                    >
                      Reset
                    </button>
                  </Col>
                </Row>
              </form>
              {/* {generateNAICScode !== "" ? (
                <Row className="mt-3">
                  <Col>
                    <Card className="border-0">
                      <CardBody>
                        <span>Generated NAICS code</span>
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <div
                            className="d-flex border p-1  mr-3 rounded flex-wrap "
                            style={{
                              width: "calc(100% - 250px)"
                            }}
                          >
                            {generateNAICScode
                              ?.substring(1, generateNAICScode.length - 1)
                              .split(",")
                              ?.map((code, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      backgroundColor: "#EAF9F1",
                                      color: "#3DBD76",
                                      borderRadius: "4px",
                                      padding: "5px 10px",
                                      margin: "0 5px"
                                    }}
                                  >
                                    <span>{code}</span>
                                  </div>
                                );
                              })}
                          </div>
                          <div
                            style={{
                              width: "216px"
                            }}
                          >
                            <Button
                              color="success"
                              className="p-2"
                              onClick={() => setViewMatchingJob(true)}
                            >
                              View Matching Jobs
                            </Button>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : null} */}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {viewMatchingJob ? (
        <Row className="mt-4">
          <Col>
            <Card>
              <CardHeader>
                <h6>Matching Jobs</h6>
              </CardHeader>
              <CardBody>
                <div className="mt-4">
                  {isLoading ? (
                    <Fragment>
                      <div className="post_error_loading">
                        <Loader />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      {error ? (
                        <Fragment>
                          <div className="post_error_loading">{error}</div>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {bidList?.paginatedList?.data?.map((item, index) => {
                            return <BidListingCard key={index} data={item} naics_code={""} />;
                          })}
                          {!error && bidList?.paginatedList?.data?.length === 0 && (
                            <div className="post_error_loading">No Matching Jobs data found.</div>
                          )}
                          <Row className="">
                            <Col className="d-flex justify-content-center">
                              <Paginate
                                pageCount={Math.ceil(totalPage)}
                                pageIndex={currentPage}
                                gotoPage={handlePageChange}
                                previousPage={handlePreviousPage}
                                nextPage={handleNextPage}
                              />
                            </Col>
                          </Row>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
    </div>
  );
}

export default JobMatchmaking;
