import Search from "components/Search/Search";
import Slider from "rc-slider";
import React, { Fragment, useEffect } from "react";
import { Button, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from "react-router-dom";
import "./OpportunityDetails.css";
import Description from "./Description";
import Contact from "./Contact";
import Documents from "./Documents";
import { useDispatch, useSelector } from "react-redux";
import { fetchBidDetailsThunkAction } from "redux/biding/actions";
import { selectBidList } from "redux/biding/selectors";
import moment from "moment";
import Loader from "components/Loader/Loader";

function OpportunityDetails() {
  const navigate = useNavigate();
  const [tab, setTab] = React.useState(1);
  const [error, setError] = React.useState(null);

  const { id } = useParams();

  const dispatch = useDispatch();
  const { isLoading, bidDetails } = useSelector(selectBidList);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (err) => {
    setError(err);
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchBidDetailsThunkAction(id, onSuccess, onError));
    }
  }, [id]);

  return (
    <div className="px-3">
      <div className="d-flex justify-content-between my-2 text-capitalize">
        <h4>{bidDetails?.contracttitle}</h4>
        <Button
          color="primary"
          className="px-3 py-2 back_to_survey_list"
          onClick={() => navigate(-1)}
        >
          <Icon icon="charm:chevron-left" color="white" className="mr-2" />
          Back
        </Button>
      </div>
      {isLoading ? (
        <Fragment>
          <div className="post_error_loading">
            <Loader />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {error ? (
            <Fragment>
              <div className="post_error_loading">{error}</div>
            </Fragment>
          ) : (
            <Fragment>
              <div>
                <Row className="">
                  <Col md="4" lg="3">
                    <div className="filter_container mt-4 p-3">
                      <div className="mb-3">
                        <h6 className="mb-3 side_detail_title">Title</h6>
                        <span className="side_detail_text">
                          {bidDetails.contracttitle ? bidDetails.contracttitle : "---"}
                        </span>
                      </div>

                      <hr className="dotted" />

                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Project number</h6>
                        <span className="side_detail_text">
                          {bidDetails.adid ? bidDetails.adid : "---"}
                        </span>
                      </div>

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Agency</h6>
                        <span className="side_detail_text">
                          {bidDetails.agencytype ? bidDetails.agencytype : "---"}
                        </span>
                      </div>

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Division</h6>
                        <span className="side_detail_text">---</span>
                      </div>

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Agency contact</h6>
                        {bidDetails?.contacts?.map((item, index) => {
                          if (item?.type === "primary") {
                            return (
                              <Fragment key={index}>
                                <span className="side_detail_text">Contact Person:</span>
                                <h6>
                                  {item?.first_name} {item?.last_name}
                                </h6>
                                <span className="side_detail_text">Email: </span>
                                <h6>{item?.email}</h6>
                                <span className="side_detail_text">Phone number: </span>
                                <h6>{item?.phone}</h6>
                              </Fragment>
                            );
                          }
                        })}
                      </div>

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Contract Number:</h6>
                        <span className="side_detail_text">
                          {bidDetails?.contractnumber ? bidDetails?.contractnumber : "---"}
                        </span>
                      </div>

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Bid release date</h6>
                        <span className="side_detail_text">
                          {moment(bidDetails?.issuedate).format("MM/DD/YYYY")}
                        </span>
                      </div>

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Bid due date</h6>
                        <span className="side_detail_text">
                          {moment(bidDetails?.duedatetime).format("MM/DD/YYYY A")}
                        </span>
                      </div>

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Project Location</h6>
                        <span className="side_detail_text">
                          {bidDetails?.jobsite ? bidDetails?.jobsite : "---"}
                        </span>
                      </div>

                      <hr className="dotted" />
                      {/* <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Generated Naics Codes</h6>
                        <span className="side_detail_text">
                          {bidDetails?.generated_naics_codes
                            ? bidDetails?.generated_naics_codes.substring(
                                1,
                                bidDetails.generated_naics_codes.length - 1
                              )
                            : "---"}
                        </span>
                      </div> */}

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">NAICS Code</h6>
                        <span className="side_detail_text">----</span>
                      </div>

                      <hr className="dotted" />
                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">County(ies):</h6>
                        <span className="side_detail_text">
                          {bidDetails?.county?.map((item, index) => (
                            <Fragment key={index}>
                              <span>
                                {item?.county.name} , {item?.county?.region}
                              </span>
                              <br />
                            </Fragment>
                          ))}
                        </span>
                      </div>

                      <hr className="dotted" />

                      <div className="mb-3 mt-3">
                        <h6 className="mb-3 side_detail_title">Job Type:</h6>
                        <span className="side_detail_text">{bidDetails?.adtype}</span>
                      </div>
                    </div>
                  </Col>
                  <Col md="8" lg="9">
                    <div className="d-flex flex-column mt-4">
                      <Nav
                        pills
                        tabs
                        fill
                        style={{
                          border: "none",
                          columnGap: "10px"
                        }}
                      >
                        <NavItem>
                          <NavLink
                            className={tab !== 1 ? "tab-button" : "tab-button-active"}
                            onClick={() => setTab(1)}
                          >
                            Description
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={tab !== 2 ? "tab-button" : "tab-button-active"}
                            onClick={() => setTab(2)}
                          >
                            Contact Info
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={tab !== 3 ? "tab-button" : "tab-button-active"}
                            onClick={() => setTab(3)}
                          >
                            Documents
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={tab !== 4 ? "tab-button" : "tab-button-active"}
                            onClick={() => setTab(4)}
                          >
                            Updates
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={tab !== 5 ? "tab-button" : "tab-button-active"}
                            onClick={() => setTab(5)}
                          >
                            Bid Results/Awards
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={tab}
                        style={{
                          backgroundColor: "#F3F3F3"
                        }}
                      >
                        <TabPane className={tab === 1 ? "active-tab" : ""} tabId={1}>
                          <div className="p-4">
                            <Description descriptionData={bidDetails?.description} />
                          </div>
                        </TabPane>
                        <TabPane className={tab === 2 ? "active-tab" : ""} tabId={2}>
                          <div>
                            <Contact
                              contactData={bidDetails?.contacts}
                              AgencyName={bidDetails?.contracttitle}
                            />
                          </div>
                        </TabPane>
                        <TabPane className={tab === 3 ? "active-tab" : ""} tabId={3}>
                          <div>
                            <Documents docData={bidDetails?.rpfDocuments} />
                          </div>
                        </TabPane>
                        <TabPane className={tab === 4 ? "active-tab" : ""} tabId={4}>
                          <div className="p-4">
                            <span>There are no updates for this ad.</span>
                            <br />
                            <span className=" mt-4">
                              {" "}
                              Please contact the issuing agency for more information.
                            </span>
                          </div>
                        </TabPane>
                        <TabPane className={tab === 5 ? "active-tab" : ""} tabId={5}>
                          <div className="p-4">
                            <h6>Bid Results</h6>
                            {bidDetails?.bid_result > 0 ? (
                              bidDetails?.bid_result?.map((item, index) => (
                                <div key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: item?.bid_result_desc }}
                                  />
                                  <br />
                                  <h6 className="mt-4">Create Date</h6>
                                  <span>{moment(item?.created_date).format("MM/DD/YYYY")}</span>
                                  <br />
                                  <h6 className="mt-4">Edit By</h6>
                                  <span>{item?.last_edit_by}</span>
                                  <br />
                                  <h6 className="mt-4">Edit Date</h6>
                                  <span>{moment(item?.last_edit_date).format("MM/DD/YYYY")}</span>
                                  <br />
                                  <div className="d-flex flex-wrap justify-content-between">
                                    <div>
                                      <h6 className="mt-4">Document Title</h6>
                                      <span>
                                        {item?.document_title ? item?.document_title : "---"}
                                      </span>
                                    </div>
                                    <div>
                                      <h6 className="mt-4">Document Description</h6>
                                      <span>
                                        {item?.document_description
                                          ? item?.document_description
                                          : "---"}
                                      </span>
                                    </div>
                                    <div>
                                      <h6 className="mt-4">Document File Name</h6>
                                      <span>
                                        {item?.document_file_name
                                          ? item?.document_file_name
                                          : "---"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <>
                                <span className="mt-4">Bid Results have not yet been entered.</span>
                                <br />
                                <span className=" mt-4">
                                  If you have questions regarding the bid results, contact the
                                  issuing agency for more information.
                                </span>
                              </>
                            )}

                            <h6 className="mt-4">Awards</h6>
                            <span className="mt-4">Awards have not yet been entered.</span>
                            <br />
                            <span className=" mt-4">
                              If you have questions regarding the awards, contact the issuing agency
                              for more information.
                            </span>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </Col>
                </Row>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}

export default OpportunityDetails;
